<template>
  <div class="page-appList" style="padding: 10px 30px 30px">
    <a-row :gutter="[30, 30]" class="app-list">
      <a-col :xs="6" :xxl="4" v-for="(item, index) in menus" :key="index">
        <div class="app-list-item" :class="{ active: item.meta.url }" @click="menuClick(item)">
          <a-tooltip placement="bottom" :title="item.meta.extra.remark">
            <div class="app-list-item-title">
              {{ item.meta.title }}
            </div>
          </a-tooltip>

          <div class="app-list-item-deco" :style="{ 'background-image': `url(${item.meta.icon})` }"></div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { menuNavigate } from '@/service/navigate'
export default {
  data() {
    return {
      menus: [
        {
          meta: {
            title: 'OA系统',
            code: 'OAxitong',
            url: '',
            icon: require('@/assets/images/appList/OA系统.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
        {
          meta: {
            title: 'ERP系统A',
            code: 'ERP-A',
            url: '',
            icon: require('@/assets/images/appList/ERP系统A.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
        {
          meta: {
            title: 'ERP系统C',
            code: 'EPR-C8',
            url: '',
            icon: require('@/assets/images/appList/ERP系统C.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
        {
          meta: {
            title: '人力资源系统',
            code: 'renliziyuanxitong0',
            url: '',
            icon: require('@/assets/images/appList/人力资源系统.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
        {
          meta: {
            title: '后台系统',
            code: 'houtaixitong8',
            url: '',
            icon: require('@/assets/images/appList/快修后台系统.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
        {
          meta: {
            title: '供应链系统A',
            code: 'gongyinglianxitongA6',
            url: '',
            icon: require('@/assets/images/appList/供应链系统B.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },

        {
          meta: {
            title: '供应链系统B',
            code: 'gongyinglianxitongB2',
            url: '',
            icon: require('@/assets/images/appList/供应链系统B.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
        {
          meta: {
            title: '供应链系统C',
            code: 'gongyinglianxitongC6',
            url: '',
            icon: require('@/assets/images/appList/供应链系统B.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
        {
          meta: {
            title: '金蝶系统',
            code: 'jindiexitong4',
            url: '',
            icon: require('@/assets/images/appList/金蝶系统.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
        {
          meta: {
            title: '工期计划系统',
            code: 'gongqixitong376',
            url: '',
            icon: require('@/assets/images/appList/金蝶系统.png'),
            target: 'blank',
            extra: {
              remark: '',
            },
          },
        },
      ],
    }
  },
  mounted() {
    const appListMenu = this.$store.state.permission.menus.find((item) => item.path === '/m/appList')
    const activeMenus = []
    const noneActiveMenus = []
    this.menus.forEach((menu) => {
      const matched = appListMenu.children.find((m) => m.meta.code === menu.meta.code)
      if (matched) {
        menu.meta.url = matched.meta.url
        menu.meta.title = matched.meta.title
        menu.meta.extra.remark = matched.meta.extra.remark
        activeMenus.push(menu)
      } else {
        noneActiveMenus.push(menu)
      }
    })
    this.menus = activeMenus.concat(noneActiveMenus)
  },
  methods: {
    menuClick(m) {
      menuNavigate(m)
    },
  },
}
</script>

<style lang="less" scoped>
.app-list-item {
  // height: calc((100vh - 160px) / 2);
  height: 300px;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 0px 30px;
  cursor: pointer;
  display: none;
  &.active {
    pointer-events: auto;
    display: flex;
  }
  .app-list-item-title {
    font-size: 26px;
    display: flex;
    align-items: center;
    .anticon {
      font-size: 16px;
      margin-left: 4px;
    }
  }
  .app-list-item-desc {
    font-size: 16px;
    color: #999;
    // padding: 10px 0;
    line-height: 30px;
    height: 60px;
    margin: 10px 0;
    overflow: hidden;
  }
  .app-list-item-deco {
    flex: 1;
    width: 60%;
    opacity: 0.6;
  }
  &:hover {
    box-shadow: 0px 6px 15px 0px rgba(99, 155, 252, 0.3);
    color: #639bfc;
    .app-list-item-deco {
      opacity: 1;
    }
  }
}
.app-list {
  .app-list-item-deco {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
